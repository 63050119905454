import React from "react";
import OwlCarousel from "react-owl-carousel";

const Pagenotfound = () => {
  return (
    <>

      <div className="main_container">
        <div className="container">
          <div className="row pageNotFoundInner">

<div className="col-sm-12 pagenotfound-heading">
  <h4>  Power your Retail, Wholesale, & Distribution Business
                        with SWIL</h4>
                      <a href="https://www.swindia.com/swilerp-cloud/" target="_blank">  <img src="https://cdn.swindia.com/assets/images/newwebsiteImages/Home/erp_desktop_ftr.webp" /></a>
</div>

            <div className="col-md-6">
              <div className="pageNotFoundLft">


              <OwlCarousel
      className="owl-theme"
      loop
      margin={10}
      nav
      items={1}
      autoplay={true}
      autoplayTimeout={3000}
      autoplayHoverPause={true}
     
    >
           <div className="item">
                      <img
                        src="https://accounts.swilerp.com/images/loginSlider1.png"
                        className="d-block "
                        alt="error"
                      />
       
                      <p className="text-center">
                      Mobile Apps: SwilPOS, SwilSORT, SwilDISPATCH and more applications combine with Retailgraph, SwilERP Desktop & Web versions. Save time and money and execute all business transitions at your fingertips.
                      </p>
                    </div>
                    <div className="item">
                      <img
                        src="https://accounts.swilerp.com/images/loginSlider2.png"
                        className="d-block "
                        alt="error"
                      />
                     
                      <p className="text-center">
                      Security: We are coupled with SQL Server as our backend technology to safeguard your critical business data. Data is protected from malicious attacks and theft by offering data encryption, limiting access, & authorization.
                      </p>
                    </div>
                    <div className="item">
                      <img
                        src="https://accounts.swilerp.com/images/loginSlider3.png"
                        className="d-block "
                        alt="error"
                      />
           
                      <p className="text-center">
                      SwilERP Web: Cloud-based application that empowers your Retail and wholesale business. Manage your business transition from anywhere and keep an eye on sales, purchases, inventories, deliveries, and more in real-time.
                      </p>
                    </div>
    </OwlCarousel>

 
              </div>
            </div>
            <div className="col-md-6">
              <div className="pageNotFoundRyt">
                <h1 className="text-center">Transaction not found!</h1>
                <p className="text-center">
                  The link is expired. Please contact your vendor
                </p>
                <a href="https://www.swindia.com" className="pageNotFoundBtn">Back to Website</a>
              </div>
            </div>
          </div>
        </div>

    <div className='PNF_bottomBar'>
   <div className='container'>
     <div className='row'>
      <div className='col-md-12'>
          <div className='PNF_bottomBarInner'>
            {window.screen.width < 567 && <li className='PNGswil_logo'>  <img src='../images/swil_logo1.png' alt='error' /></li> }
            <ul className='PNF_bottomBarList'>
              <li><a href='https://learn.swindia.com/#/home'>SWIL Learn</a> </li>
              <li><a href='https://www.swindia.com/beta-program/'>SWIL BETA PROGRAM</a></li>
              {window.screen.width > 567 && <li className='PNGswil_logo'>  <img src='../images/swil_logo1.png' alt='error' /></li>}
              
              <li><a href='https://www.swindia.com/swil-blog/'>SWIL Blog</a> </li>
              <li><a href='https://www.swindia.com/contact-us/'>SWIL Contact</a> </li>
             
            </ul>
          </div>
      </div>
     </div>
   </div>
</div>
</div>


    </>
  );
};

export default Pagenotfound;
