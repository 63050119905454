import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Header } from './Header';
import { Left } from './Left';
import { Right } from './Right';



function Home() {
  const navigate = useNavigate();
  const [transactiondtl, settransactiondtl] = useState({});
  const [loading, setloading] = useState();
  const search = useLocation().search;
  const id = search.slice(4)

 
  // const { id } = useParams();
  async function getapI() {
    console.log(id);
    setloading(true);
    // https://swilshare-api.azurewebsites.net/api/transaction
    await axios
      .post(`https://swilshare-api.azurewebsites.net/api/transaction`,{id:id})
      .then((res) => {
        const response = res.data.transaction;
        settransactiondtl(response);
        setloading(false);
        return res;
      })
      .catch((err) => {
        setloading(false);
        navigate('/pagenotfound');
      });
  }
  useEffect(() => {
    getapI();
  }, []);

  if (loading) {
    return (
      <div className='col-sm-6 text-center full-page-loader'>
        <div className="Swil-logo-loading ">
              <span className="spin"></span>
              <img
                src="https://swilshare.blob.core.windows.net/swil/companylogo-circle.png"
                alt="swillogo"
              />
            </div>
       
      </div>
    );
  }




  return (
    <>
      <section className='desktop_view'>
        <div className='container-fluid'>
          <Header transactiondtl={transactiondtl} />
          <div className='row mid_box'>
            <Left transactiondtl={transactiondtl} />
            <Right transactiondtl={transactiondtl} />
          </div>
        </div>
      </section>
      <div className='mobile-view-tabs'>
        <ul className='nav nav-tabs' id='myTab' role='tablist'>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link active'
              id='home-tab'
              data-bs-toggle='tab'
              data-bs-target='#home'
              type='button'
              role='tab'
              aria-controls='home'
              aria-selected='true'>
              Invoice
            </button>
          </li>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link'
              id='profile-tab'
              data-bs-toggle='tab'
              data-bs-target='#profile'
              type='button'
              role='tab'
              aria-controls='profile'
              aria-selected='false'>
              Details
            </button>
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div
            className='tab-pane fade show active'
            id='home'
            role='tabpanel'
            aria-labelledby='home-tab'>
            {' '}
            <Right transactiondtl={transactiondtl} />
          </div>
          <div
            className='tab-pane fade'
            id='profile'
            role='tabpanel'
            aria-labelledby='profile-tab'>
            {' '}
            <Left transactiondtl={transactiondtl} />
          </div>
        </div>
      </div>

      {/* <section className="mobile_view">
        <div className="container-fluid">
          <div className="row mid_box">

            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0"> <div className="col-md-4 mid_box_lft pl-0">

                <div className="mid_box_lft_frst">

                  <Left transactiondtl={transactiondtl} />


                </div>


              </div>
              </div>
              <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
                <Right transactiondtl={transactiondtl} />
              </div>

            </div>


          </div>
        </div>
      </section> */}
    </>
  );
}

export default Home;
